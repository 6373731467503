import React from 'react'
import PropTypes from 'prop-types'

const EmbedSource = ({ src, title, ...remainingProps }) => (
  <iframe
    src={src}
    title={title}
    {...remainingProps}
    frameBorder="0"
    loading="lazy"
  />
)

EmbedSource.propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string,
}

export default EmbedSource
